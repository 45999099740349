<template>
  <div class="photoboot">
    <div class="photoboot__draggables">
      <template v-if="!completed">
        <div class="pb-1">PHOTOBOOTH (drag & drop)</div>
        <div class="photoboot__scrollable">
          <div class="photoboot__boot-list">
            <img
              :src="image"
              class="photoboot__boot-list--boot"
              v-for="(image, index) in images"
              alt="photoboot"
              :key="`${image}-${index}`"
              draggable="true"
              @dragstart="handleDragStart"
              @dragend="handleDragEnd"
            />
          </div>
        </div>
      </template>
      <template v-else>
        <div class="photoboot__result-text">
          <div>IMAGE <br />SUBMITTED</div>
        </div>
      </template>
    </div>
    <div class="photoboot__image">
      <ResizableText
        v-if="isViewerAuditorLike"
        message="The users are taking pictures"
        class="h-full w-full"
      />

      <template v-else>
        <div v-show="!!track" class="photoboot__container-wrap">
          <div
            ref="container"
            class="photoboot__canvas-container"
            v-show="!capturedImageUrl"
          />

          <div class="photoboot__image-container" v-if="capturedImageUrl">
            <img :src="capturedImageUrl" :width="width" :height="height" />
          </div>

          <div
            class="photoboot__canvas-overlay"
            @drop="handleDrop"
            @dragover="handleDragOver"
            v-if="dragging && !capturedImageUrl"
          />
        </div>
        <v-flex
          grow
          justify-center
          v-if="!track"
          class="photoboot__spinner-container"
        >
          <v-progress-circular indeterminate class="photoboot__spinner" />
        </v-flex>

        <UserVideo
          v-if="track"
          :track="track"
          class="photoboot__user-video"
          @ready="initialize()"
          ref="video"
        />
      </template>
    </div>
    <!-- TODO - simplify -->
    <div class="photoboot__controls" v-if="canSubmit">
      <template v-if="!imageBlob && !completed">
        <RtbButton
          key="take-photo"
          variant="icon"
          size="lg"
          circle
          title="Take a photo"
          :disabled="working"
          :id="`${_uid}-take-photo-button`"
          @click="takePicture"
        >
          <svg-icon name="camera-solid" style="position: relative; top: -1px" />
        </RtbButton>
        <b-tooltip
          key="take-photo-tooltip"
          :target="`${_uid}-take-photo-button`"
          placement="right"
        >
          Take a photo
        </b-tooltip>
      </template>

      <template v-if="(!imageUrl && !!imageBlob && !completed) || working">
        <RtbButton
          key="submit"
          variant="icon"
          size="lg"
          circle
          title="Submit"
          :disabled="working"
          :id="`${_uid}-submit-button`"
          @click="submitPicture"
        >
          <svg-icon name="checkmark" />
        </RtbButton>
        <b-tooltip
          key="save-tooltip"
          :target="`${_uid}-submit-button`"
          placement="right"
        >
          {{ working ? "Submitting..." : "Submit Photo" }}
        </b-tooltip>
      </template>

      <template v-if="destroyables.length && !imageBlob && !completed">
        <RtbButton
          key="reset-1"
          variant="icon"
          size="lg"
          circle
          dark
          title="Reset"
          animated
          :id="`${_uid}-reset-button-1`"
          @click="resetBooth"
        >
          <svg-icon name="undo-regular" />
        </RtbButton>
        <b-tooltip
          key="reset-1-tooltip"
          :target="`${_uid}-reset-button-1`"
          placement="right"
        >
          Reset
        </b-tooltip>
      </template>

      <template v-if="imageBlob && !completed">
        <RtbButton
          key="reset-2"
          variant="icon"
          size="lg"
          circle
          title="Reset"
          animated
          :id="`${_uid}-reset-button-2`"
          :disabled="working"
          @click="retakePicture"
        >
          <svg-icon name="undo-regular" />
        </RtbButton>
        <b-tooltip
          key="reset-2-tooltip"
          :target="`${_uid}-reset-button-2`"
          placement="right"
        >
          Reset
        </b-tooltip>
      </template>
      <RtbButton
        v-if="completed && isMobile"
        class="font-bold capitalize text-lg px-3"
        style="min-width: 180px"
        @click="takeNextPhoto()"
      >
        Take Next Photo
      </RtbButton>
    </div>
  </div>
</template>

<script>
import Konva from "konva"
import { mapGetters, mapActions } from "vuex"
import { BTooltip } from "bootstrap-vue"
import "drag-drop-touch"
import { uploadMedia } from "@/services/storage.service"
import { GameMixin } from "@/mixins"
import uniqid from "uniqid"
import ResizableText from "@/components/GroupTeams/Common/Games/ResizableText.vue"
import { RtbButton } from "@/components/ui"
import { ActionTypes as TwilioActionTypes } from "@/store/TwilioModule"
import UserVideo from "@/components/GroupTeams/Common/User/UserVideo.vue"
import { VideoConstraints, VideoConstraintsType } from "@/store/TwilioModule"
import User from "@shared/User"

const { CREATE_LOCAL_VIDEO_TRACK } = TwilioActionTypes
const DEFAULT_OBJECT_SIZE = 50

export default {
  name: "Photobooth",
  components: {
    BTooltip,
    RtbButton,
    ResizableText,
    UserVideo
  },
  mixins: [GameMixin],
  props: {
    mode: String,
    mission: Object
  },
  data() {
    return {
      track: null,
      dragging: false,
      stage: null,
      boothLayer: null,
      imageLayer: null,
      transformer: null,
      image: null,
      destroyables: [],
      imageBlob: null,
      imageUrl: null,
      animation: null,
      working: false,
      capturedImageUrl: null,
      canvas: null,
      ctx: null,
      accentColor: null,
      width: 0,
      height: 0,
      canvasImage: null,
      allowNext: false
    }
  },
  beforeDestroy() {
    this.animation?.stop()
    this.stage?.off("click tap")
    this.stage?.destroy()
    this.ctx = undefined
    this.cavans = undefined
  },
  async created() {
    this.canvas = document.createElement("canvas")
    this.ctx = this.canvas.getContext("2d")

    this.accentColor = getComputedStyle(
      document.documentElement
    ).getPropertyValue("--primary-accent-color")
  },
  mounted() {
    setTimeout(async () => {
      const track = this.$store.state.twilio.users[this.user?.id]?.videoTrack
      this.track =
        track ||
        (await this[CREATE_LOCAL_VIDEO_TRACK](
          VideoConstraints[VideoConstraintsType.RECORDING]
        ))
    }, 1000)
  },
  computed: {
    ...mapGetters(["missionCompleted"]),
    ...mapGetters({ users: "onlineUsersArray" }),
    isViewerAuditorLike() {
      return this.$store.getters["group/isViewerAuditorLike"]
    },
    canSubmit() {
      if (!this.isGamePage) return true
      if (User.isHost(this.user)) return true
      return User.isPlayer(this.user)
    },
    // TODO - component shouldn't know about the page
    isGamePage() {
      return this.$route.name === "game"
    },
    completed() {
      return this.isGamePage && this.missionCompleted && !this.allowNext
    },
    instructions() {
      return this.mission?.instructions
    },
    images() {
      return (this.mission?.photoboothImages || []).map(decodeURI)
    },
    token() {
      return this.$store.state.auth.token
    },
    user() {
      return this.$store.getters.user
    },
    isMobile() {
      return User.isMobile(this.user)
    }
  },
  methods: {
    ...mapActions("twilio", [CREATE_LOCAL_VIDEO_TRACK]),
    onTap(e) {
      // do nothing if clicked on a transfomer
      if (e?.target?.parent === this.transformer) return
      // if click on empty area - remove all selections
      if (e.target === this.stage || e.target.attrs.draggable === false) {
        this.transformer.nodes([])
        this.boothLayer.draw()
        return
      }
      // did we press shift or ctrl?
      const pressed = e.evt.shiftKey || e.evt.ctrlKey || e.evt.metaKey
      const selected = this.transformer.nodes().indexOf(e.target) >= 0
      if (!pressed && !selected) {
        // if no key pressed and the node is not selected
        // select just one
        e.target.moveToTop()
        this.transformer.moveToTop()
        this.transformer.nodes([e.target])
      } else if (pressed && selected) {
        // if we pressed keys and node was selected
        // we need to remove it from selection:
        const nodes = this.transformer.nodes().slice() // use slice to have new copy of array
        // remove node from array
        nodes.splice(nodes.indexOf(e.target), 1)
        this.transformer.nodes(nodes)
      } else if (pressed && !selected) {
        // add the node into selection
        const nodes = this.transformer.nodes().concat([e.target])
        this.transformer.nodes(nodes)
      }
      this.boothLayer.draw()
    },
    onTick() {
      const $el = this.$refs.video?.$el

      if (!$el) return

      const ctx = this.ctx
      const width = this.width
      const height = this.height
      const videoWidth = $el.videoWidth
      const videoHeight = $el.videoHeight

      ctx.save()
      ctx.translate(width, 0)
      ctx.scale(-1, 1)

      const hRatio = width / videoWidth
      const vRatio = height / videoHeight
      const ratio = Math.max(hRatio, vRatio)
      const scaledWidth = videoWidth * ratio
      const scaledHeight = videoHeight * ratio
      const centerShiftX = (width - scaledWidth) / 2
      const centerShiftY = (height - scaledHeight) / 2

      ctx.drawImage(
        $el,
        0,
        0,
        videoWidth,
        videoHeight,
        centerShiftX,
        centerShiftY,
        scaledWidth,
        scaledHeight
      )

      ctx.restore()
    },
    initialize() {
      this.$nextTick(() => {
        this.animation?.stop()
        this.stage?.off("click tap")
        this.stage?.destroy()

        const n = Math.min(
          this.$refs.container?.clientWidth || 1,
          this.$refs.container?.clientHeight || 1
        )

        const width = n
        const height = n

        this.width = width
        this.height = height

        this.canvas.width = width
        this.canvas.height = height

        this.imageLayer = new Konva.Layer()
        this.boothLayer = new Konva.Layer()

        this.transformer = new Konva.Transformer({
          keepRatio: true,
          rotateAnchorOffset: 25,
          anchorFill: "white",
          anchorStroke: "black",
          borderStroke: this.accentColor,
          anchorSize: 8
        })

        this.boothLayer.add(this.transformer)

        this.stage = new Konva.Stage({
          container: this.$refs.container,
          width,
          height
        })

        this.stage.add(this.imageLayer, this.boothLayer)

        this.canvasImage = new Konva.Image({
          image: this.canvas,
          x: 0,
          y: 0,
          width,
          height,
          draggable: false,
          listening: false
        })

        this.imageLayer.add(this.canvasImage)

        this.animation = new Konva.Animation(this.onTick, this.imageLayer)

        this.stage.on("click tap", this.onTap)

        this.animation.start()
      })
    },
    dragStart(e) {
      this.dragging = true
    },
    dragEnd(e) {
      this.dragging = false
    },
    addItem(src, { x, y }) {
      const image = new Image()
      image.src = src
      image.crossOrigin = "Anonymous"
      const onReady = () => {
        const { width, height } = image
        const newHeight = height / (width / DEFAULT_OBJECT_SIZE)
        const newWidth = DEFAULT_OBJECT_SIZE
        image.removeEventListener("load", onReady)
        const boothImg = new Konva.Image({
          image,
          x: x || 30,
          y: y || 30,
          width: newWidth,
          height: newHeight,
          draggable: true
        })
        this.boothLayer.add(boothImg)
        this.transformer.nodes([boothImg])
        this.boothLayer.batchDraw()
        this.destroyables.push(boothImg)
      }
      image.addEventListener("load", onReady)
    },
    resetBooth() {
      this.destroyables.forEach(node => node.destroy())
      this.transformer.nodes([])
      this.boothLayer.batchDraw()
      this.destroyables = []
      this.animation?.stop()
      this.animation?.start()
      this.imageBlob = null
    },
    async takePicture() {
      this.working = true
      this.transformer.nodes([])

      this.boothLayer.children
        .filter(booth => booth !== this.transformer)
        .forEach(booth => booth.draggable(false))

      this.boothLayer.batchDraw()
      const dataUrl = this.stage.toDataURL({ pixelRatio: 3 })
      this.capturedImageUrl = dataUrl
      const blob = await (await fetch(dataUrl)).blob()
      this.imageBlob = blob
      this.animation?.stop()
      this.working = false
    },
    retakePicture() {
      this.imageBlob = null
      this.capturedImageUrl = null

      this.boothLayer.children
        .filter(booth => booth !== this.transformer)
        .forEach(booth => booth.draggable(true))

      this.boothLayer.batchDraw()
      this.animation?.start()
    },
    async submitPicture() {
      this.working = true
      const fileName = `gamephotos/${uniqid()}-${this.user.username}.jpg`
      try {
        this.imageUrl = await uploadMedia({
          fileName,
          blob: this.imageBlob,
          token: this.token
        })

        this.allowNext = false
        if (!this.isGamePage) {
          this.takeNextPhoto()
        }
      } catch (e) {
        console.error(e.message)
        // TO DO
        // repalce with global info window
        alert(
          "It looks like you are behind a firewall or VPN and therefore can't save your photo."
        )
      }
      if (this.imageUrl) {
        this.hasImage = true
        this.missionStatus = "completed"
        await this.checkAnswer({
          sentMissionID: this.mission?.id
        })
      }
      this.working = false
    },
    takeNextPhoto() {
      this.retakePicture()
      this.resetBooth()
      this.allowNext = true
    },
    handleDragStart(e) {
      const {
        target: { src },
        offsetX,
        offsetY
      } = e
      const payload = JSON.stringify({
        imgSrc: src,
        offsetX,
        offsetY
      })
      this.dragging = true
      e.dataTransfer.setData("text/plain", payload)
    },
    handleDragEnd() {
      this.dragging = false
    },
    handleDrop(e) {
      const { layerX, layerY } = e
      const { imgSrc, offsetX, offsetY } = JSON.parse(
        e.dataTransfer.getData("text/plain")
      )
      const x = layerX - offsetX / 2 - 5
      const y = layerY - offsetY / 2 - 5

      e.preventDefault()

      if (imgSrc) this.addItem(imgSrc, { x, y })
    },
    handleDragOver(e) {
      e.preventDefault()
      e.dataTransfer.dropEffect = "move"
    }
  }
}
</script>

<style lang="scss">
.photoboot {
  position: relative;
  display: flex;
  flex: 1;
  max-height: 100%;

  &__scrollable {
    flex-grow: 1;
    height: 0;
    overflow: hidden;
  }

  .orientation-portrait & {
    padding: 8px;
  }

  &__result-text {
    font-size: 30px;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
  }

  &__container-wrap {
    display: flex;
    flex: 1;
  }

  &__draggables {
    flex: 1;
    display: flex;
    flex-direction: column;

    .orientation-portrait & {
      height: 0;
    }
  }

  &__image {
    flex: 1;
    display: flex;
    position: relative;
  }

  &__controls {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 0;
    position: relative;
    justify-content: center;

    & > *:not(:last-child) {
      margin: 0 0 12px 0;
    }
  }

  & > *:not(:last-child) {
    margin: 0 12px 0 0;
  }

  &__user-video {
    width: auto !important;
    height: auto !important;
    position: absolute;
    max-width: 100%;
    max-height: 100%;
    margin: 0 !important;
    object-fit: initial !important;
    z-index: -1000;
    opacity: 0;
  }

  &__boot-list {
    overflow: auto;
    height: 100%;
    &--boot {
      margin-bottom: 3px;
      margin-right: 3px;
      max-width: 56px;
      height: auto;
    }
  }

  .rtb-button {
    margin: 0;
  }

  &__btn-wrap {
    margin-bottom: 10px;
    &:not(:last-child) {
      margin-right: 5px;
    }
  }

  &__canvas-container {
    transform: translateZ(0);
  }

  &__canvas-container,
  &__image-container {
    & > * {
      border: 3px solid $primary_accent_color;
      border-radius: 10px !important;
      background-color: $primary_accent_color;
      box-sizing: content-box;
      align-self: center;
    }

    flex: 1;
    justify-content: center;
    display: flex;

    canvas,
    img {
      border-radius: inherit;
    }
  }

  &__canvas-overlay {
    position: absolute;
    top: 10px;
    left: 10px;
    right: 10px;
    bottom: 10px;
    background-color: transparent;
    z-index: 2;
    border: dashed #ccc 3px;
  }

  &__start-video-button:hover {
    background-color: $color-brown;
    color: $color-white;
    outline: none;
  }

  &__spinner-container {
    width: 100%;
    display: flex;
    align-items: center;
  }

  &__spinner {
    color: $primary_accent_color;
  }
}

@media screen and (orientation: portrait) {
  .photoboot {
    flex-direction: column;
    & > *:not(:last-child) {
      margin: 0 0 12px 0;
    }
    &__controls {
      flex-direction: row;
      & > *:not(:last-child) {
        margin: 0 12px 0 0;
      }
    }
  }
}
</style>
